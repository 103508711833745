<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form class="form" @submit.prevent autocomplete="off">
        <p>Tên nhóm</p>
        <ValidationProvider name="Tên nhóm" :rules="{required:true,max:20}" v-slot="{ errors }"
                            vid="name">
          <el-input placeholder="Nhập tên nhóm" v-model="newGroup.nameGroup" :maxlength="21"></el-input>
          <div class="fv-plugins-message-container">
            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
          </div>
        </ValidationProvider>
        <div class="d-lg-flex justify-content-between align-items-center mt-4">
          <p class="mb-0">Danh sách thành viên</p>
          <button type="button" class="btn btn-success" @click="addMember()">Thêm thành viên</button>
        </div>
        <div class="mt-3">
          <table class="table table-vertical-center b-table table-bordered">
            <thead class="thead-light">
            <tr>
              <th scope="col">Trưởng nhóm</th>
              <th scope="col">Học viên</th>
              <th scope="col">Tên thay thế</th>
              <th scope="col">Nhân vật</th>
              <th></th>
            </tr>
            </thead>
            <tbody v-if="listStudentSelected.length === 0">
            <tr>
              <td colspan="5" class="text-center">
                Chưa có thành viên.
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr v-for="(studentSelected,index) in listStudentSelected" :key="index">
              <td class="text-center">
                <input type="radio" :id="studentSelected.idStudent" v-model="idCaptain"
                       :value="studentSelected.idStudent" checked/>
              </td>
              <td>{{ studentSelected?.nameStudent }}</td>
              <td>
                <el-input v-model="studentSelected.otherName" maxlength="20"></el-input>
              </td>
              <td>
                <el-select v-model="studentSelected.figure" placeholder="Chọn nhân vật" @change="checkListFigure()"
                           clearable>
                  <el-option
                      v-for="item in listFigure"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                      :disabled="listFigureCopy.find((figure)=>figure.id === item.id) === undefined">
                  </el-option>
                </el-select>
              </td>
              <td style="text-align: center">
                <i class="el-icon-delete mr-1" style="color: red ; font-size: 16px;cursor: pointer"
                   @click="deleteStudent(index,studentSelected.idStudent)"></i>
              </td>
            </tr>
            </tbody>
          </table>
          <div>
            <p style="white-space: pre-wrap; color: red">{{ error }}</p>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <button type="button" class="btn btn-secondary mr-2" @click="cancelModal()" :disabled="loading">Hủy</button>
            <button type="button" class="btn btn-info" @click="handleSubmit(createNewGroup)" :disabled="loading"><i
                v-if="loading" class="el-icon-loading"></i> Xác nhận
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {CREATE_CLAN, EDIT_CLAN, LIST_CHARACTERS_KID} from "../../../../core/services/store/service.module";

export default {
  name: "modal-add-group",
  components: {},
  data() {
    return {
      input: '',
      listFigure: [],
      listFigureCopy: [],
      newGroup: {
        nameGroup: ''
      },
      idCaptain: null,
      error: '',
      loading: false
    }
  },
  props: {
    listStudentSelected: {
      type: Array,
      default() {
        return []
      }
    },
    nameAndIdGroup: {
      type: Object,
      default() {
        return {};
      }
    },
    isEditGroup: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  async mounted() {
    await this.getListCharacter()
    if (this.isEditGroup) {
      this.newGroup.nameGroup = this.nameAndIdGroup?.nameGroup;
      this.idCaptain = this.listStudentSelected.find((student) => student.isCaptain).idStudent;
      this.checkListFigure()
    }
  },
  methods: {
    addMember() {
      this.$emit('addMember')
    },
    checkListFigure() {
      let ArrStudentFigure = this.listStudentSelected.map((e) => e.figure)
      this.listFigureCopy = this.listFigure.filter((figure) => !ArrStudentFigure.includes(figure.id))
    },
    createNewGroup() {
      this.error = this.checkErrorCreate()
      if (!this.error) {
        this.loading = true
        let members = this.listStudentSelected.map((student) => {
          return {
            id: student.idStudent,
            is_captain: +student?.idStudent === +this.idCaptain,
            nickname: student.otherName,
            character_id: student.figure
          }
        })
        let data = {
          name: this.newGroup.nameGroup,
          classroom_id: this.$route.params.id,
          members: members
        }
        if (this.isEditGroup) {
          data.clan_id = this.nameAndIdGroup.idGroup
        }
        this.$store.dispatch(this.isEditGroup ? EDIT_CLAN : CREATE_CLAN, data).then((res) => {
          this.$notify({
            title: `${this.isEditGroup ? 'Sửa thành công' : 'Thêm mới thành công'}`,
            message: 'Thành công',
            type: 'success'
          });
          this.$emit('reloadList')
        }).catch((e) => {
          if (e.data?.data?.message_validate_form) {
            this.$refs.form.setErrors(e.data.data.message_validate_form);
          }
          this.$notify.error({
            title: 'Lỗi',
            message: `${e?.data?.data?.message_validate_form?.name[0]}`
          });
        }).finally(() => {
          this.loading = false
        })
      }
    },
    checkErrorCreate() {
      let error = ''
      if (this.listStudentSelected.length === 0) {
        error += 'Vui lòng thêm học viên vào nhóm\n'
        return error
      }
      if (!this.idCaptain) {
        error += 'Vui lòng chọn trưởng nhóm của nhóm\n'
      }
      let NotHaveOtherName = this.listStudentSelected.find((student) => !student.otherName) != null
      if (NotHaveOtherName) {
        error += 'Vui lòng cập nhật tên thay thế của tất cả thành viên trong nhóm\n'
      }
      let NotHaveFigure = this.listStudentSelected.find((student) => !student.figure) != null;
      if (NotHaveFigure) {
        error += 'Vui lòng cập nhật nhân vật của tất cả thành viên trong nhóm\n'
      }
      return error
    },
    deleteStudent(index, idStudent) {
      this.listStudentSelected.splice(index, 1)
      idStudent === this.idCaptain ? this.idCaptain = null : ''
      this.checkListFigure();
    },
    cancelModal() {
      this.$emit('cancelModalGroup')
    },
    async getListCharacter() {
      await this.$store.dispatch(LIST_CHARACTERS_KID).then((data) => {
        this.listFigure = data.data;
        this.listFigureCopy = [...this.listFigure];
        return
      })
    }
  },

}
</script>

<style scoped>
.el-radio__label {
  display: none !important;
}
</style>