export class Student{
    constructor(id,nameStudent,phoneStudent,otherName='',selected = false,figure = null,isCaptain = false) {
        this.idStudent = id;
        this.nameStudent = nameStudent;
        this.phoneStudent = phoneStudent;
        this.isCaptain = isCaptain;
        this.figure = figure;
        this.selected = selected;
        this.otherName = otherName;
    }
}