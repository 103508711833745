<template>
<div class="p-4 " style="background-color: white">
  <header class="head d-lg-flex justify-content-between" >
    <div>
    <router-link :to="{ name: 'classrooms' }"
                 v-slot="{ href, navigate }">
      <a :href="href" @click="navigate" class="btn btn-dark btn-sm mr-1" tabindex="0"
         aria-controls="dt-basic-example" type="button"><span><i
          class="fal fa-chevron-left mr-1"></i> Trở lại trang quản lý lớp học</span>
      </a>
    </router-link>
  </div>
    <div>
      <button type="button" class="btn btn-success" @click="addGroup()">Thêm mới nhóm</button>
    </div>
  </header>
  <div class="example-preview b-table-sticky-header mt-4" >
    <table class="table table-vertical-center b-table table-bordered">
      <thead>
      <tr>
        <th scope="col" >#</th>
        <th scope="col" >Team</th>
        <th scope="col" >Tên học viên</th>
        <th scope="col" >Chức danh</th>
        <th scope="col" >Tên nhân vật</th>
        <th scope="col" >Level</th>
        <th scope="col" >Điểm sao</th>
        <th scope="col" >Thao tác</th>
      </tr>
      </thead>
      <tbody v-for="(group,indexGroup) in listGroup" :key="indexGroup">
      <tr style="background-color: #fff5e0">
        <td ></td>
        <td>{{group?.name}}</td>
        <td colspan="5" ></td>
        <td>
          <button type="button" class="btn btn-primary" @click="openEditGroup(group.members,group?.name,group?.id)">Chi tiết nhóm</button>
          <button type="button" class="btn btn-danger ml-3" @click="deleteGroup(group?.id)">Xóa nhóm</button>
        </td>
      </tr>
       <tr v-for="(member,indexMember) in group.members" :key="indexMember">
         <td>{{indexMember + 1}}</td>
         <td>{{group?.name}}</td>
         <td>{{member?.name}} {{member.nickname?`(${member.nickname})`:''}}</td>
         <td>{{member?.is_captain ?'Trưởng nhóm':'Thành viên'}}</td>
         <td>{{member?.character_name}}</td>
         <td>{{member?.level}}</td>
         <td>{{member?.point_star}}</td>
         <td>
           <button type="button" class="btn btn-info" @click="addExpForMember(member)">Thưởng</button>
           <button type="button" class="btn btn-secondary ml-4" @click="leaveGroup(member,group?.id)"><i class="fal fa-sign-out mr-1"></i>Rời nhóm</button>
         </td>
       </tr>
      </tbody>
      <tbody>
      <tr style="background-color: #deeffd" v-if="listNotHaveGroup.length > 0">
        <td></td>
        <td colspan="7">Chưa có team</td>
      </tr>
      <tr v-for="(student,indexStudent) in listNotHaveGroup" :key="indexStudent">
        <td>{{indexStudent + 1}}</td>
        <td></td>
        <td>{{student.name}} {{student.nickname?`(${student.nickname})`:''}}</td>
        <td></td>
        <td></td>
        <td>{{student.level }}</td>
        <td>{{student.point_star}}</td>
        <td></td>
      </tr>
      </tbody>
    </table>
  </div>
  <el-dialog
      :title="isEditGroup?'Chi tiết nhóm':'Thêm mới nhóm'"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
      :show-close="false"
  >
  <modal-add-group v-if="dialogVisible" @addMember="dialogVisibleMember = true" :listStudentSelected="listStudentSelected" @cancelModalGroup="cancelModalGroup" :isEditGroup="isEditGroup" :nameAndIdGroup="nameAndIdGroup" @reloadList="reloadList()"></modal-add-group>
  </el-dialog>
  <el-dialog
      title="Chọn thành viên vào nhóm"
      :visible.sync="dialogVisibleMember"
      width="40%"
      :show-close="false"
      :close-on-click-modal="false"
  >
    <modal-add-member v-if="dialogVisibleMember" @listStudent="setListStudentSelected" :listStudentSelected="listStudentSelected" @cancelAddMember="dialogVisibleMember = false"></modal-add-member>
  </el-dialog>
  <el-dialog
      :title="`Thưởng học viên ${addExpMember?.name}`"
      :visible.sync="dialogVisibleExp"
      width="30%"
      :show-close="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
  >
    <modal-add-exp :addExpMember="addExpMember" @reloadListExp="reloadListExp()" @cancelModal="dialogVisibleExp = false"></modal-add-exp>
  </el-dialog>
</div>
</template>
<script>
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import ModalAddGroup from "./component/modal-add-group.vue";
import ModalAddMember from "./component/modal-add-member.vue";
import {
  DELETE_CLAN,
  GET_LIST_CLAN,
  LEAVE_ClAN,
  LIST_STUDENT_NOT_IN_CLAN
} from "../../../core/services/store/service.module";
import {Student} from "./model/student";
import ModalAddExp from "./component/modal-add-exp.vue";

export default {
  name: "list-team-kid",
  components: {ModalAddExp, ModalAddMember, ModalAddGroup},
  data(){
    return{
      dialogVisible: false,
      dialogVisibleMember: false,
      listStudentSelected : [],
      listGroup: [],
      isEditGroup: false,
      nameAndIdGroup: {},
      listNotHaveGroup: [],
      dialogVisibleExp: false,
      addExpMember : {}
    }
  },
  mounted() {
    this.getListGroup();
    let clas_name = localStorage.getItem("className");
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý lớp học"},
      {title: `Tổ chức lớp: ${clas_name}`, icon: 'far fa-flag'}
    ]);
  },
  methods:{
    addGroup(){
      this.dialogVisible = true
    },
    setListStudentSelected(ListStudentSelected){
      this.listStudentSelected = ListStudentSelected;
      this.dialogVisibleMember = false
    },
    cancelModalGroup(){
      this.dialogVisible = false;
      this.isEditGroup = false;
      this.listStudentSelected = [];
    },
    getListGroup(){
      this.$store.dispatch(GET_LIST_CLAN,{classroom_id: this.$route.params.id}).then((data)=>{
        if(!data.error){
          this.listGroup = data?.data??[];
        }
        this.getListNotHaveGroup()
      })
    },
    openEditGroup(members,nameGroup,idGroup){
      this.dialogVisible = true;
      this.isEditGroup = true;
      this.nameAndIdGroup = {nameGroup: nameGroup, idGroup: idGroup};
      this.listStudentSelected = members.map((member)=> new Student(member.student_id,member.name,member.phone,member.nickname,true,member.character_id,member.is_captain));
    },
    deleteGroup(idGroup){
      this.$confirm('Bạn có muốn xóa không', 'Warning', {
        confirmButtonText: 'Có',
        cancelButtonText: 'Không',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch(DELETE_CLAN,{clan_id: idGroup}).then(()=>{
          this.$notify({
            title: `Đã xóa thành công`,
            message: 'Thành công',
            type: 'success'
          });
          this.getListGroup()
        }).catch(()=>{
          this.$notify.error({
            title: 'Lỗi',
            message: `Xóa thất bại`
          });
        })
      })
    },
    reloadList(){
      this.dialogVisible = false;
      this.isEditGroup = false;
      this.listStudentSelected = [];
      this.getListGroup()
    },
    leaveGroup(member,idGroup){
      this.$confirm('Bạn có muốn rời nhóm không', 'Warning', {
        confirmButtonText: 'Có',
        cancelButtonText: 'Không',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch(LEAVE_ClAN,{student_id: member.student_id,clan_id: idGroup}).then(()=>{
          this.$notify({
            title: `Đã rời nhóm thành công`,
            message: 'Thành công',
            type: 'success'
          });
          this.getListGroup()
        }).catch(()=>{
          this.$notify.error({
            title: 'Lỗi',
            message: `Rời nhóm thất bại`
          });
        })
      })
    },
    getListNotHaveGroup(){
      let payload = {
        classroom_id: this.$route.params.id
      }
      this.$store.dispatch(LIST_STUDENT_NOT_IN_CLAN,payload).then((res)=>{
        console.log(res);
        this.listNotHaveGroup = res?.data;
      })
    },
    addExpForMember(Member){
      this.addExpMember = Member;
      this.dialogVisibleExp = true;
    },
    reloadListExp(){
      this.dialogVisibleExp = false;
      this.getListGroup();
    }
  }
}
</script>
<style scoped>

</style>