<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form class="form" @submit.prevent autocomplete="off">
        <ValidationProvider name="Tên nhóm" rules="required" v-slot="{ errors }"
                            vid="name">
          <el-input-number v-model="exp" controls-position="right"  :min="1" :step-strictly="true" style="width: 100%"></el-input-number>
          <div class="fv-plugins-message-container">
            <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
          </div>
        </ValidationProvider>
        <div class="d-flex justify-content-end mt-3">
          <button type="button" class="btn btn-secondary mr-2" @click="cancelModal()" :disabled="loading">Hủy</button>
          <button type="button" class="btn btn-info" @click="handleSubmit(addExp)" :disabled="loading"><i
              v-if="loading" class="el-icon-loading"></i>Xác nhận
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import {GIVE_EXP} from "../../../../core/services/store/service.module";

export default {
  name: "modal-add-exp",
  data(){
    return {
      exp:1,
      loading:false
    }
  },
  props:{
    addExpMember:{
      type: Object,
      default(){
        return {}
      }
    }
  },
  methods:{
    addExp(){
      this.loading = true;
      this.$store.dispatch(GIVE_EXP,{user_id: this.addExpMember.student_id,exp: this.exp,classroom_id:this.$route.params.id}).then((data)=>{
        this.$notify({
          title: `Tặng sao thành công`,
          message: 'Thành công',
          type: 'success'
        });
        this.$emit('reloadListExp')
      }).catch(()=>{
        this.$notify.error({
          title: 'Lỗi',
          message: `Đã có lỗi xẩy ra`
        });
      }).finally(()=>{
        this.loading = false
      })
    },
    cancelModal(){
      this.$emit('cancelModal')
    }
  }
}
</script>
<style scoped>

</style>