<template>
  <div>
    <div v-if="loading" class="text-center">
      <i class="el-icon-loading" style="font-size: 20px"></i>
    </div>
    <div v-else-if="listStudent.length <= 0" class="text-center">
      <div  style="border: 1px solid #cccccc;padding: 7px">Không có học viên</div>
    </div>
    <div class="row" v-else>
      <div class="row col-6" >
        <div style="width: 99%" class="row ml-1">
          <div class="col-8" style="border: 1px solid #cccccc;padding: 7px;border-right: none">Học viên</div>
          <div class="col-4" style="border: 1px solid #cccccc;padding: 7px;text-align: center">Chọn</div>
        </div>

      </div>
      <div class="row col-6" >
        <div style="width: 99%" class="row ml-1">
          <div class="col-8" style="border: 1px solid #cccccc;padding: 7px;border-right: none">Học viên</div>
          <div class="col-4" style="border: 1px solid #cccccc;padding: 7px;text-align: center">Chọn</div>
        </div>

      </div>
        <div class="row col-6" v-for="(student,index) in listStudent" :key="index" style="width: 80%">
          <div style="width: 99%" class="row ml-1">
            <div class="col-8" style="border: 1px solid #cccccc;padding: 7px;border-right: none;overflow-wrap: break-word;">{{student.nameStudent}} - {{student.phoneStudent}}</div>
            <div class="col-4" style="border: 1px solid #cccccc;padding: 7px;text-align: center"><el-checkbox v-model="student.selected"></el-checkbox></div>
          </div>
        </div>
    </div>
    <div class="d-flex justify-content-end mt-3">
      <button type="button" class="btn btn-secondary mr-2" @click="cancel()">Hủy</button>
      <button type="button" class="btn btn-info" @click="confirm()">Xác nhận</button>
    </div>
  </div>
</template>
<script>
import {Student} from "../model/student";
import {LIST_STUDENT_NOT_IN_CLAN} from "../../../../core/services/store/service.module";

export default {
  name: "modal-add-member",
  data(){
    return{
       listStudent: [],
      loading: false
    }
  },
  props:{
    listStudentSelected :{
      type: Array,
      default(){
        return []
      }
    }
  },
  mounted() {
   this.getListStudentNotInClan()
  },
  methods:{
     confirm(){
       this.$emit('listStudent',JSON.parse(JSON.stringify(this.listStudent.filter((student)=>student.selected).concat(this.listStudentSelected))))
     },
    cancel(){
      this.$emit('cancelAddMember')
    },
    getListStudentNotInClan(){
       this.loading = true;
       let payload = {
         classroom_id: this.$route.params.id
       }
       this.$store.dispatch(LIST_STUDENT_NOT_IN_CLAN,payload).then((res)=>{
         this.listStudent = this.listStudentSelected.length > 0 ? res?.data?.map((e)=>{
          let studentSelected = this.listStudentSelected.find((student)=>student.idStudent === e.student_id);
          if(studentSelected){
            return studentSelected
          }
          return new Student(e.student_id,e.name,e.phone,e.nickname)
         })  : res?.data?.map((e)=>new Student(e.student_id,e.name,e.phone,e.nickname));
         if(this.listStudentSelected.length > 0 && (this.listStudent.find((student)=>student.student_id === this.listStudentSelected[0].idStudent))==null){

           this.listStudent =  this.listStudent.concat(this.listStudentSelected).filter((obj, index, self) =>
                   index === self.findIndex((t) => (
                       t.idStudent === obj.idStudent
                   ))
           ).filter((student)=>!student.selected);
         }
       }).finally(()=>{
         this.loading = false;
       })
    }
  },
}
</script>
<style scoped>

</style>